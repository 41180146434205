<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import PageHeader from '@components/page-header'
import { BASE_IMAGE_URL } from '@src/config/configs'
import axios from '@/axios'
import Pagination from '@src/components/base/pagination'
import Search from '@src/components/base/search'
import moment from 'moment'
import Chat from '@components/chat'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Chat',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, Pagination, Search, Chat },
  data() {
    return {
      show: false,
      baseImageUrl: BASE_IMAGE_URL,
      search_types: [
        {
          // key: 'content_title', value: 'Sender'
        },
      ],
      sortByList: [
        // { key: 'Title', value: 'content_title' },
        // { key: 'Text', value: 'content_text' },
      ],
      search: {},
      page: 1,
      data: [],
      pagination: {},
      sort_by: '',
      chat: {},
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    renderDate(date) {
      const formatDate = new Date(date)
      return moment(formatDate, 'MM/DD/YYYY').format('Do MMM YYYY')
    },
    onPage(page) {
      this.page = page
      this.onLoad()
    },
    onSearch(search) {
      this.search = search
      this.page = 1
      this.onLoad()
    },
    async onLoad() {
      await axios
        .get('/api/chat', {
          params: {
            page: this.page,
            ...this.search,
            sort_by: this.sort_by,
            orderByField: 'updateAt',
            orderBy: 'desc',
          },
        })
        .then((res) => {
          this.data = res.data.data
          this.pagination = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },

    async openModal(data) {
      // console.log(data)
      this.show = true
      this.chat = data

      // let messagedata = data.messages.map((x) => {
      //   return {
      //     image: require('@assets/images/users/avatar-7.jpg'),
      //     name: x.sender_id,
      //     message: x.message,
      //     time: x.createdAt.substring(0, 10),
      //   }
      // })
      // this.chatMessages = messagedata

      // this.modalVisible = true
    },
    // moment: function() {
    //   return moment()
    // },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Chat</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <Search :types="search_types" @onSearch="onSearch($event)">
              <div class="input-group" style="margin-right:5px">
                <div class="input-group-prepend">
                  <label class="input-group-text">Sort by:</label>
                </div>
                <select
                  class="custom-select"
                  v-model="sort_by"
                  @change="onLoad()"
                >
                  <option value>All</option>
                  <option
                    v-for="x in sortByList"
                    :key="x.key"
                    :value="x.value"
                    >{{ x.key }}</option
                  >
                </select>
              </div>
            </Search>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Sender</th>
                    <th scope="col">Receiver</th>
                    <th scope="col">Last Message</th>
                    <th scope="col">Time</th>
                    <th scope="col"> messages count </th>
                    <th scope="col"> reply status </th>

                    <th scope="col"> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td scope="row">{{
                      page == 1
                        ? page - 1 + index + 1
                        : (page - 1) * 20 + (index + 1)
                    }}</td>
                    <td scope="row">{{ item.sender_id.name }}</td>
                    <td scope="row">{{ item.receiver_id.name }}</td>
                    <td scope="row">{{
                      item.recent ? item.recent.last_message : null
                    }}</td>
                    <td scope="row">{{
                      item.recent ? renderDate(item.recent.date) : null
                    }}</td>
                    <td>{{ item.count }}</td>
                    <td>{{ item.reply ? '✔' : '✖' }}</td>

                    <td class="d-flex">
                      <button
                        class="btn btn-info p-0 px-2 py-1 mr-2"
                        @click="openModal(item)"
                        >Open Chat</button
                      >
                    </td>
                  </tr>
                  <b-modal
                    id="modal-xl"
                    hide-footer
                    size="lg"
                    v-model="show"
                    ref="modal"
                    title=" Chat Box"
                  >
                    <div>
                      <Chat :chat="chat" title="" />
                    </div>
                  </b-modal>
                </tbody>
              </table>

              <Pagination
                :data="pagination"
                :page="page"
                @onPage="onPage($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
